
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexFngg1VZzPRz8gaZzuDEeYBRhpo4WwrwZ_45MlEF1YYr_MMeta } from "/opt/build/repo/frontend/pages/index.vue?macro=true";
import { default as styleC68_5E62vCejzz44sXkNS0sKLC6Gkd4Str395WahvX8Meta } from "/opt/build/repo/frontend/pages/style.vue?macro=true";
import { default as _91slug_93JeDKFbsOnMNteV44FbYQgOB8k3ES0ESAsAu_ib4an1EMeta } from "/opt/build/repo/frontend/pages/[slug].vue?macro=true";
import { default as indexveBbVADewJgirn6hbyVBCcsJpaCMKdKN48o5ia3egIEMeta } from "/opt/build/repo/frontend/pages/events/index.vue?macro=true";
import { default as _91slug_93XCU9gNC8Gc9uedvQNnXgpZWwqFowwpUPMRgToIzShasMeta } from "/opt/build/repo/frontend/pages/events/[slug].vue?macro=true";
import { default as _91_46_46_46slug_93NqZFSFkvsem03N8gra46X6NNjMcO2yB5l8LBDF6a5WwMeta } from "/opt/build/repo/frontend/pages/job/[...slug].vue?macro=true";
import { default as indexKiH1PYn8uY39y7JWWtWSEaJG_45N_45l5F53LLLLP5ZvZMkMeta } from "/opt/build/repo/frontend/pages/join-us/index.vue?macro=true";
import { default as index6Jd3ir7H3Wz3TFwrrFWfiKsZlTyTYotvaMpWyX6khRwMeta } from "/opt/build/repo/frontend/pages/insights/index.vue?macro=true";
import { default as _91slug_93WF_45OPblxWcnlByX_451YCbxAxB2_45vUlH1HuJezdM7BESUMeta } from "/opt/build/repo/frontend/pages/insights/[slug].vue?macro=true";
import { default as indexK3UvIb1XbLusyO4owKJ6WEJbN_uvkRdcOaa00lFSI1gMeta } from "/opt/build/repo/frontend/pages/what-we-do/index.vue?macro=true";
import { default as _91slug_93Qfl8AirCiXkIGIXCPx_9m2iVhkMPU3R2sMys2xc7O7gMeta } from "/opt/build/repo/frontend/pages/what-we-do/[slug].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/frontend/pages/index.vue")
  },
  {
    name: "style",
    path: "/style",
    component: () => import("/opt/build/repo/frontend/pages/style.vue")
  },
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/opt/build/repo/frontend/pages/[slug].vue")
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/opt/build/repo/frontend/pages/events/index.vue")
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    component: () => import("/opt/build/repo/frontend/pages/events/[slug].vue")
  },
  {
    name: "job-slug",
    path: "/job/:slug(.*)*",
    component: () => import("/opt/build/repo/frontend/pages/job/[...slug].vue")
  },
  {
    name: "join-us",
    path: "/join-us",
    component: () => import("/opt/build/repo/frontend/pages/join-us/index.vue")
  },
  {
    name: "insights",
    path: "/insights",
    component: () => import("/opt/build/repo/frontend/pages/insights/index.vue")
  },
  {
    name: "insights-slug",
    path: "/insights/:slug()",
    component: () => import("/opt/build/repo/frontend/pages/insights/[slug].vue")
  },
  {
    name: "what-we-do",
    path: "/what-we-do",
    component: () => import("/opt/build/repo/frontend/pages/what-we-do/index.vue")
  },
  {
    name: "what-we-do-slug",
    path: "/what-we-do/:slug()",
    component: () => import("/opt/build/repo/frontend/pages/what-we-do/[slug].vue")
  }
]