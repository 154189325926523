<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
  >
    <g clip-path="url(#clip0_1715_27)">
      <path d="M9.07302 9V0.92309H1" stroke="currentColor" />
      <path d="M9.07319 0.926929L0.273193 9.72693" stroke="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_1715_27">
        <rect
          width="10"
          height="10"
          fill="white"
          transform="matrix(1 0 0 -1 0 10)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
