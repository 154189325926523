<script setup>
const error = useError();
const route = useRoute();

const errorCache = JSON.parse(JSON.stringify(error.value));

watch(
  () => route.fullPath,
  async () => {
    await clearError();
  },
);

// name after '/' becomes key on the globalData object
// global/footer-menu -> globalData.value.footerMenu
const globalDataEndpoints = [
  "global/footer",
  "global/events",
  "menu/main-menu",
];

const { globalData, setGlobalData } = useTTCState();

await callOnce(async () => {
  try {
    // run in parallel to speed things up
    const responses = await Promise.allSettled(
      globalDataEndpoints.map((url) => useCmsFetch(url)),
    );

    const globalData = responses.reduce((acc, cur, idx) => {
      if (cur.status !== "fulfilled") return;

      const key = camelize(globalDataEndpoints[idx].split("/").at(-1));
      return { ...acc, [key]: cur.value.data };
    }, {});

    setGlobalData(globalData);
  } catch (error) {
    throw createError({
      statusCode: 500,
      statusMessage: "Could not get global data",
    });
  }
});

const { setGlobalTheme } = useTheme();
setGlobalTheme("white");

const goBackHome = () => {
  window.location.href = "/";
};
const infos = ref(null);
const animators = ref([]);
onMounted(() => {
  infos.value?.querySelectorAll("p").forEach((item) => {
    animators.value.push(new TextAnimator(item));
  });
});

const inView = () => {
  if (animators.value.length < 1) return;
  animators.value.forEach((animator, index) => {
    animator.animate();
  });
};

useHead({
  title: "404 | .the tech collective",
  meta: [
    { property: "og:title", content: "404" },
    { name: "description", content: "Error page" },
  ],
});
</script>

<template>
  <main class="error flex h-full min-h-[100.1vh] min-w-[100vw] flex-col">
    <globals-header />
    <globals-colors />
    <NuxtLayout class="bg-black text-white">
      <div class="page error-page">
        <ui-bleed>
          <component-behavior-in-view
            :once="true"
            @in-view="inView"
            :threshold="0"
          >
            <div
              ref="infos"
              class="error-page-content flex h-screen flex-col items-center justify-center gap-[40px]"
            >
              <ui-font-text type="error">{{
                errorCache.statusCode
              }}</ui-font-text>
              <ui-font-text type="body">An error occurred</ui-font-text>
              <ui-call-to-action
                :button="true"
                button-label="Back home"
                aria-label="Back home button"
                :has-background="true"
                @click="goBackHome()"
              >
                <ui-font-text type="s-bold">{{
                  formatCTALabel("Back home")
                }}</ui-font-text></ui-call-to-action
              >
            </div>
          </component-behavior-in-view>
        </ui-bleed>
      </div>
    </NuxtLayout>
    <globals-footer class="mt-auto" />
  </main>
</template>
